<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <vx-card v-if="userDetail">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <div id="user-edit-tab-info">
                <!-- Avatar Row -->
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="flex items-start flex-col sm:flex-row">
                      <img
                        :src="
                          userDetail.profileImage !== ''
                            ? userDetail.profileImage
                            : defaultAvatar
                        "
                        class="mr-8 rounded h-24 w-24"
                      />
                      <!--<vs-avatar
                        :src="(userDetail.profileImage!=='')?userDetail.profileImage:defaultAvatar"
                        size="80px"
                        class="mr-4"
                      />-->
                      <div>
                        <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
                          {{ userDetail.firstName + " " + userDetail.lastName }}
                        </p>
                        <input
                          type="file"
                          class="hidden"
                          ref="update_avatar_input"
                          @change="upload_avatar"
                          accept="image/*"
                        />

                        <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
                        <vs-button
                          class="mr-4 mb-4"
                          @click="$refs.update_avatar_input.click()"
                          >Change Avatar</vs-button
                        >
                        <!--<vs-button
                          type="border"
                          class="mr-4 mb-4"
                          @click="$refs.update_avatar_input.click()"
                          >Change Avatar</vs-button
                        >-->

                        <vs-button type="border" color="danger" @click="update_avatar('')"
                          >Remove Avatar</vs-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Content Row -->
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input
                      class="w-full mt-4"
                      label="First Name"
                      v-model="userDetail.firstName"
                      v-validate="'alpha_spaces'"
                      name="firstName"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('firstName')"
                      >{{ errors.first("firstName") }}</span
                    >
                    <vs-input
                      class="w-full mt-4"
                      label="Last Name"
                      v-model="userDetail.lastName"
                      v-validate="'alpha_spaces'"
                      name="lastName"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('lastName')"
                      >{{ errors.first("lastName") }}</span
                    >
                    <vs-input
                      class="w-full mt-4"
                      label="Username"
                      v-model="userDetail.username"
                      name="username"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('username')"
                      >{{ errors.first("username") }}</span
                    >

                    <vs-input
                      class="w-full mt-4"
                      label="Email"
                      v-model="userDetail.email"
                      type="email"
                      v-validate="'required|email'"
                      name="email"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('email')"
                      >{{ errors.first("email") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/2 w-full">
                    <div class="mt-4">
                      <label class="vs-input--label">Status</label>
                      <v-select
                        v-model="status_local"
                        :clearable="false"
                        :options="statusOptions"
                        v-validate="'required'"
                        name="status"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('status')"
                        >{{ errors.first("status") }}</span
                      >
                    </div>

                    <div class="mt-4">
                      <div class="vx-row">
                        <div class="vx-col w-1/4 mt-10">
                          <vue-country-code
                              @onSelect="onCountrySelect"
                              class="w-full"
                              :defaultCountry="userDetail.phoneNumberCountry"
                              :enabledCountryCode="true"
                              :disabledFetchingCountry="true"
                              :preferredCountries="['au', 'us', 'np']">
                          </vue-country-code>
                        </div>
                        <div class="vx-col w-3/4">
                            <vs-input
                              class="w-full mt-4"
                              label="Phone Number"
                              v-model="userDetail.phoneNumber"
                              name="phoneNumber"
                            />
                            <span
                              class="text-danger text-sm"
                              v-show="userDetail.phoneNumber && userDetail.phoneVerification.isVerified!=true"
                              >{{ userDetail.phoneVerification.verificationCodeSentToCountryCode }} {{ userDetail.phoneVerification.verificationCodeSentTo }}: Unverified</span
                            >
                        </div>
                      </div>

                    </div>
                    <div class="mt-4">
                      <vs-input
                        class="w-full mt-4"
                        label="About Me"
                        v-model="userDetail.aboutMe"
                        v-validate="'alpha_spaces'"
                        name="aboutMe"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('aboutMe')"
                        >{{ errors.first("aboutMe") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                      <vs-button
                        class="ml-auto mt-2"
                        @click="save_changes"
                        :disabled="!validateForm"
                        >Save Changes</vs-button
                      >
                      <vs-button
                        class="ml-4 mt-2"
                        type="border"
                        color="warning"
                        @click="reset_data"
                        >Reset</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Password" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <p>
                    New password will be generated and email will be sent to
                    <strong>{{ userDetail.email }}</strong>
                  </p>
                  <div class="demo-alignment">
                    <vs-button
                      color="primary"
                      type="border"
                      @click="changePasswordHandle"
                      >Change Password</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { userMixin } from "../../mixins/userMixin";
import { ErrorBag } from "vee-validate";
import axios from "../../axios";
import VueCountryCode from "vue-country-code";
import userDetailsVue from './userDetails.vue';
const bag = new ErrorBag();
export default {
  components: {
    vSelect,
    VueCountryCode
  },
  mixins: [userMixin],
  data() {
    return {
      userDetail: null,
      originalDetail: null,
      user_not_found: false,
      defaultAvatar:
        "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
      selectedAvatar: null,
      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
      statusOptions: [
        { label: "Active", value: "Active" },
        //{ label: "Self-Deactivated", value: "Self-Deactivated" },
        //{ label: "Inactive", value: "Inactive" },
        //{ label: "Disabled", value: "Disabled" },
        // { label: "Deleted", value: "Deleted" },
        { label: "In-Active", value: "InActive" },
      ],
    };
  },
  watch: {
    activeTab() {
      this.fetch_user_data(this.$route.params.userId);
    },
  },
  methods: {
     onCountrySelect({name, iso2, dialCode}) {
       console.log(name, iso2, dialCode);
       this.userDetail.phoneNumberCountryCode = '+'+dialCode;
       this.userDetail.phoneNumberCountry = iso2;
     },
    getUserDetail(id) {
      let _vue = this;
      this.getUser(id).then((res) => {
        _vue.userDetail = res;
        _vue.originalDetail = Object.assign({}, { ...res })
      });
    },
    save_changes() {
      let _vue = this;
      if (!this.validateForm) return;
      if (this.userDetail.accountStatus === 'InActive') this.userDetail.accountStatus = 'Deleted';
      this.saveUser(this.userDetail).then((res) => {
        if (res.message.status == "success") {
          console.log(res.userDetail);
          //_vue.getUserDetail(res.userDetail._id)
          _vue.userDetail = res.userDetail;
          _vue.$vs.notify({
            title: "Success",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$router.push('/admin/user-management')

        } else {
          console.log(res.message.message);
          _vue.$vs.notify({
            title: "Error.",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
      // Here will go your API call for updating data
      // You can get data in "this.data_local"
    },
    reset_data() {
      this.userDetail = Object.assign({}, { ...this.originalDetail })
    },
    upload_avatar(event) {
      let _vue = this;
      console.log("base url", process.env.VUE_APP_BASE_API_URL);
      console.log("file select event", event);
      this.selectedAvatar = event.target.files[0];
      let fd = new FormData();
      fd.append("profileImage", this.selectedAvatar);
      axios
        .post(
          process.env.VUE_APP_BASE_API_URL + "/api/v1/upload/profileImage",
          fd
        )
        .then((res) => {
          let profileImageUrl = res.data.data.uploadUrl;
          _vue
            .saveProfileImage({
              _id: _vue.userDetail._id,
              profileImage: profileImageUrl,
            })
            .then((res) => {
              _vue.update_avatar(profileImageUrl);
            })
            .catch((err) => {
              console.log("update profile image error", err);
            });
        })
        .catch((err) => {
          console.log("image upload error", err);
        });
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
    update_avatar(profileImageUrl) {
      let _vue = this;
      _vue
        .saveProfileImage({
          _id: _vue.userDetail._id,
          profileImage: profileImageUrl,
        })
        .then((res) => {
          _vue.userDetail.profileImage = profileImageUrl;
        })
        .catch((err) => {
          console.log("update profile image error", err);
        });
    },
    changePasswordHandle() {
      let _vue = this;
      this.changePassword(this.$route.params.id).then((res) => {
        if (res.message.status == "success") {
          _vue.$vs.notify({
            title: "Success",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        } else {
          _vue.$vs.notify({
            title: "Error.",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },
  computed: {
    status_local: {
      get() {
        return {
          label: this.userDetail.accountStatus === 'Deleted' ? 'InActive' : this.userDetail.accountStatus,
          value: this.userDetail.accountStatus === 'Deleted' ? 'InActive' : this.userDetail.accountStatus
        };
      },
      set(obj) {
        console.log(obj);
        this.userDetail.accountStatus = obj.value;
      },
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  created() {
    let userId = this.$route.params.id;
    this.getUserDetail(userId);
  },
};
</script>
